import Bowser from "bowser";

export const isSafari = () => {
	const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName().includes("Safari");
};

export const isAndroid = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getOSName().includes("Android");
};

export const isIOS = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    console.log(browser.getOSName());   
    return browser.getOSName().includes("iOS");
};

export const getUserAgent = () => {
    return Bowser.parse(window.navigator.userAgent);
};